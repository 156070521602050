@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./color.css";

@layer base {
  html[data-theme="dark"] {
    --background-1: 32, 32, 32;
    --background-2: 26, 26, 26;
    --background-3: 43, 43, 43;
    --background-4: 56, 56, 56;
    --background-5: 70, 70, 70;
    /*  */
    --primary-50: 255, 246, 230;
    --primary-100: 254, 226, 176;
    --primary-200: 253, 212, 138;
    --primary-300: 252, 193, 84;
    --primary-400: 252, 181, 51;
    --primary-500: 251, 171, 0;
    --primary-600: 228, 147, 0;
    --primary-700: 178, 115, 0;
    --primary-800: 138, 89, 0;
    --primary-900: 105, 68, 0;

    --secondary-50: 230, 249, 254;
    --secondary-100: 176, 235, 252;
    --secondary-200: 138, 226, 250;
    --secondary-300: 84, 212, 248;
    --secondary-400: 51, 204, 246;
    --secondary-500: 0, 191, 244;
    --secondary-600: 0, 174, 222;
    --secondary-700: 0, 136, 173;
    --secondary-800: 0, 105, 134;
    --secondary-900: 0, 80, 102;

    --success-50: 233, 247, 234;
    --success-100: 185, 229, 190;
    --success-200: 152, 217, 158;
    --success-300: 104, 199, 114;
    --success-400: 75, 189, 86;
    --success-500: 30, 172, 44;
    --success-600: 27, 157, 40;
    --success-700: 21, 122, 31;
    --success-800: 17, 95, 24;
    --success-900: 13, 72, 18;

    --warning-50: 252, 248, 232;
    --warning-100: 246, 232, 184;
    --warning-200: 241, 221, 149;
    --warning-300: 235, 205, 101;
    --warning-400: 231, 196, 71;
    --warning-500: 225, 181, 25;
    --warning-600: 205, 165, 23;
    --warning-700: 160, 129, 18;
    --warning-800: 124, 100, 14;
    --warning-900: 95, 76, 11;

    --error-50: 249, 231, 231;
    --error-100: 237, 182, 182;
    --error-200: 228, 146, 146;
    --error-300: 215, 96, 96;
    --error-400: 208, 65, 65;
    --error-500: 255, 73, 73;
    --error-600: 178, 16, 16;
    --error-700: 139, 13, 13;
    --error-800: 108, 10, 10;
    --error-900: 82, 8, 8;

    --black-50: 13, 72, 18;
    --black-100: 182, 182, 182;
    --black-200: 157, 157, 157;
    --black-300: 113, 113, 113;
    --black-400: 85, 85, 85;
    --black-500: 43, 43, 43;
    --black-600: 39, 39, 39;
    --black-700: 31, 31, 31;
    --black-800: 24, 24, 24;
    --black-900: 18, 18, 18;

    --gray-50: 241, 241, 241;
    --gray-100: 212, 212, 212;
    --gray-200: 192, 192, 192;
    --gray-300: 163, 163, 163;
    --gray-400: 145, 145, 145;
    --gray-500: 117, 117, 117;
    --gray-600: 106, 106, 106;
    --gray-700: 83, 83, 83;
    --gray-800: 64, 64, 64;
    --gray-900: 49, 49, 49;

    --white: 220, 220, 220;
    --white-50: 254, 254, 254;
    --white-100: 253, 253, 253;
    --white-200: 252, 252, 252;
    --white-300: 251, 251, 251;
    --white-400: 250, 250, 250;
    --white-500: 249, 249, 249;
    --white-600: 227, 227, 227;
    --white-700: 177, 177, 177;
    --white-800: 137, 137, 137;
    --white-900: 105, 105, 105;
  }

  html[data-theme="light"] {
    /* --background-1: #dfdfdf;
    --background-2: #e6e6e6e6;
    --background-3: #dadada;
    --background-4: #ccc; */
    --background-1: 218, 218, 218;
    --background-2: 224, 224, 224;
    --background-3: 207, 207, 207;
    --background-4: 196, 196, 196;
    --background-5: 180, 180, 180;
    /*  */
    --primary-50: 255, 246, 230;
    --primary-100: 254, 226, 176;
    --primary-200: 253, 212, 138;
    --primary-300: 252, 193, 84;
    --primary-400: 252, 181, 51;
    --primary-500: 18, 120, 158;
    --primary-600: 228, 147, 0;
    --primary-700: 178, 115, 0;
    --primary-800: 138, 89, 0;
    --primary-900: 105, 68, 0;

    --secondary-50: 230, 249, 254;
    --secondary-100: 176, 235, 252;
    --secondary-200: 138, 226, 250;
    --secondary-300: 84, 212, 248;
    --secondary-400: 51, 204, 246;
    --secondary-500: 0, 191, 244;
    --secondary-600: 0, 174, 222;
    --secondary-700: 0, 136, 173;
    --secondary-800: 0, 105, 134;
    --secondary-900: 0, 80, 102;

    --success-50: 233, 247, 234;
    --success-100: 185, 229, 190;
    --success-200: 152, 217, 158;
    --success-300: 104, 199, 114;
    --success-400: 75, 189, 86;
    --success-500: 30, 172, 44;
    --success-600: 27, 157, 40;
    --success-700: 21, 122, 31;
    --success-800: 17, 95, 24;
    --success-900: 13, 72, 18;

    --warning-50: 252, 248, 232;
    --warning-100: 246, 232, 184;
    --warning-200: 241, 221, 149;
    --warning-300: 235, 205, 101;
    --warning-400: 231, 196, 71;
    --warning-500: 225, 181, 25;
    --warning-600: 205, 165, 23;
    --warning-700: 160, 129, 18;
    --warning-800: 124, 100, 14;
    --warning-900: 95, 76, 11;

    --error-50: 249, 231, 231;
    --error-100: 237, 182, 182;
    --error-200: 228, 146, 146;
    --error-300: 215, 96, 96;
    --error-400: 208, 65, 65;
    --error-500: 196, 18, 18;
    --error-600: 178, 16, 16;
    --error-700: 139, 13, 13;
    --error-800: 108, 10, 10;
    --error-900: 82, 8, 8;

    --black-50: 30, 30, 30;
    --black-100: 70, 70, 704;
    --black-200: 80, 80 80;
    --black-300: 100, 100 1000;
    --black-400: 130, 130 130;
    --black-500: 140, 140, 140;
    --black-600: 145, 145, 145;
    --black-700: 157, 157, 157;
    --black-800: 182, 182, 182;
    --black-900: 190, 190, 190;

    /* --black-900: 13, 72, 18; */

    --gray-50: 49, 49, 49;
    --gray-100: 64, 64, 64;
    --gray-200: 83, 83, 83;
    --gray-300: 106, 106, 106;
    --gray-400: 117, 117, 117;
    --gray-500: 145, 145, 145;
    --gray-600: 163, 163, 163;
    --gray-700: 192, 192, 192;
    --gray-800: 212, 212, 212;
    --gray-900: 241, 241, 241;

    --white: 58, 58, 58;
    --white-50: 105, 105, 105;
    --white-100: 137, 137, 137;
    --white-200: 177, 177, 177;
    --white-300: 227, 227, 227;
    --white-400: 249, 249, 249;
    --white-500: 250, 250, 250;
    --white-600: 251, 251, 251;
    --white-700: 252, 252, 252;
    --white-800: 253, 253, 253;
    --white-900: 254, 254, 254;
  }

  html[data-theme="neon"] {
  }
}

/* .text-orange-500 {
  @apply !text-primary-500;
}
.text-orange-400 {
  @apply !text-primary-400;
}
.text-orange-600 {
  @apply !text-primary-600;
} */

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* --primary: #fba200; */
  /* yellow */
  --black-light: #bebebe;
  --primary: #fbab00;
  --accent: #e9e9e9;
  --light-yellow: #ffe6b0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="file"] {
  cursor: pointer;
}
.uploader {
  @apply inline-flex relative overflow-hidden cursor-pointer w-[100px];
}
.uploader input[type="file"] {
  @apply opacity-0 text-[0px] cursor-pointer h-full w-full max-w-full bg-none absolute left-0 top-0 z-[2];
}

* {
  @apply scroll-smooth;
}
main {
  /* background: var(--background-1); */
}

* {
  @apply text-white;
}
.input,
select {
  @apply duration-100;
  /* @apply !outline-none; */
}

.max-w-screen {
  max-width: 100vw;
}

@media (sm) {
  .drawer-content-width-full {
    max-width: calc(100vw - 64px);
  }
  .drawer-content-width-mini {
    max-width: calc(100vw - 256px);
  }
}

.collaps input.peer {
  position: absolute;
}
.custom-collapse-drawer {
  padding: 0;
}
.custom-collapse-drawer .collapse-title {
  min-height: initial;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.custom-collapse-drawer .peer {
  position: absolute;
}

.modal_screen_scroll .modal {
  overflow: auto;
  height: 100vh;
  align-items: start;
}

.modal_screen_scroll .modal.modal-open .modal-box {
  max-height: initial;
}
/* custom theme */

/* navbar */
.Navbar {
  background: rgb(var(--background-1));
}

/* drop-down */
.dropdown-content {
  @apply bg-black-500 rounded-lg px-[15px] py-0;
}

.dropdown-content li {
  @apply border-gray-500 border-b-[1px];
}
.dropdown-content a {
  background: none !important;
  @apply py-4 px-0 text-t2 capitalize;
}

.dropdown-content :last-child {
  @apply border-[#0000]
  /* border: none; */;
}

.dropdown-content-child {
  @apply py-0 px-[15px] rounded-lg bg-black-500 !border !border-gray-800;
}

.dropdown-content-child li {
  @apply border-gray-500 border-b-[1px];
}
.dropdown-content-child a {
  background: none !important;
  @apply py-4 px-0 text-t2 capitalize;
}

.dropdown-content-child > :last-child {
  border: none;
}

.dropdown-nested li > ul {
  transform: translatex(100%) scale(0);
}
.dropdown-nested li:hover > ul {
  transform: translatex(101%) scale(1);
}

/* typography */
.text-b1 {
  line-height: 155%;
}
.text-h3 {
  line-height: 155%;
}

/* container */
/* sm	640px
md	768px
lg	1024px
xl	1280px
2xl	1536px */

.row {
  @apply grid grid-cols-4 gap-2 md:grid-cols-12 md:gap-6;
}

/* grid */
.grid-rows-auto {
  grid-template-rows: auto;
}

/* @media (sm) {
  .container-row {
    width: calc(100vw - 64px);
    max-width: 1440px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (md) {
  .container-row {
    width: calc(100vw - 86px);
    max-width: 1440px;
  }
}

@media (xl) {
  .container-row {
    width: calc(100vw - 160px);
    max-width: 1440px;
  }
} */
/* 





*/
.react-tel-input .form-control {
  @apply !border-gray-200 !border !bg-black-500 !text-white-400 !rounded-lg !w-full !h-12 !pl-16;
}
.react-tel-input .flag-dropdown {
  border-radius: var(--rounded-btn, 0.5rem) 0 0 var(--rounded-btn, 0.5rem) !important;
}
.react-tel-input .selected-flag {
  border-radius: var(--rounded-btn, 0.5rem) 0 0 var(--rounded-btn, 0.5rem) !important;
}
.input {
  @apply rounded-lg text-white-400;
}

.react-tel-input .flag-dropdown {
  @apply !border-l-0 !border-t-0 !border-b-0 !bg-[#0000] !border-black-400
      !my-3 !ml-2 !pr-[2px];
}

.react-tel-input .flag-dropdown.open .selected-flag {
  @apply !bg-[#0000];
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  @apply !bg-[#0000];
}
.react-tel-input .country-list {
  @apply !mt-4 card !rounded-xl !w-[260px] md:w-[300px];
}
.react-tel-input .form-control.phone-input {
  @apply input !input-bordered !border !bg-black-600 !text-white-400;
  /* border-radius: 8px !important; */
}
.phone-input::placeholder {
  @apply !text-gray-400;
}

.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .country:hover {
  @apply !bg-[#0000];
}
.react-tel-input .country-list .country.highlight .country-name {
  @apply !bg-[#0000] !text-primary;
}

/* 



*/

@layer components {
  .center {
    @apply flex justify-center items-center;
  }
  .modal-box {
    @apply card !p-4 md:!p-6;
  }

  /* swiper */
  .custom-swiper .swiper-pagination-bullets {
    @apply flex items-center justify-center gap-[6px];
  }
  .custom-swiper .swiper-pagination-bullet {
    @apply transition-all duration-300 cursor-pointer rounded-lg !m-0 w-[10px] h-[10px] !bg-gray-300;
    background: rgb(var(--background-3));
    opacity: 1;
  }
  .custom-swiper_navigation {
    @apply z-10 w-14 h-14 bg-black-500 rounded-full flex items-center justify-center;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.35);
  }
  .custom-swiper .swiper-pagination-bullet-active {
    @apply border-none !bg-primary w-6;
    height: 10px;
  }
  /* swiper-pagination-bullet-active */
  /* buttons */
  .btn {
    @apply font-normal min-h-8 !h-8 md:min-h-12 text-b3 md:text-b1 lg:text-[20px] min-w-fit first-letter:!uppercase;
  }
  .btn-primary {
    @apply text-black-500;
  }
  .btn.btn-circle {
    @apply !rounded-full aspect-square;
  }
  /* 
  
  
  
  
  */

  /* input */
  /* .input {
    @apply rounded-lg;
  }
  .btn.btn-sm {
    @apply md:min-h-[40px] md:text-b1;
  } */
  /* .btn {
    @apply p-0;
  } */
  .gradient-01 {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.23) 10.59%,
      rgba(0, 0, 0, 0.9) 82.03%
    );
    /* url(/images/products/slider/1.jpeg), lightgray 50% / cover no-repeat; */
  }
  .container-max-w-sm {
    width: calc(100vw - 64px);
  }
  .container-max-w-md {
    width: calc(100vw - 86px);
  }
  .container-max-w-lg {
    width: calc(100vw - 128px);
    max-width: 1240px;
    /* max-width: 1240px; */
  }
  .container-max-w-xl {
    /* width: calc(100vw - 160px); */
    /* max-width: 1600px; */
  }
  /* container-row */
  .container-row {
    @apply w-full px-4 mx-auto sm:container-max-w-sm md:container-max-w-md lg:container-max-w-lg;
    /* xl:container-max-w-xl; */
  }
  /* input */
  .input {
    @apply h-[46px] min-h-[46px] md:h-16 md:min-h-12 border border-black-400 bg-black-600;
  }
  .footer::placeholder {
    @apply text-b1 md:text-[20px];
  }
  /*  */
  .ScrollNavigationVertical *,
  .ScrollNavigationHorizental * {
    @apply transition-all duration-300;
  }
  .ScrollNavigationHorizental {
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.35);
  }
  label {
    @apply first-letter:capitalize;
  }
  .label-text {
    @apply text-white font-medium md:font-normal text-b1 md:text-t1 first-letter:capitalize;
  }
  .label-checkbox {
    @apply flex-row-reverse items-start gap-4;
  }

  .label-checkbox .label-text {
    @apply leading-155% !text-gray-200 text-b2 !font-normal md:text-t1;
  }
  /* typography */
  .typo-h1 {
    @apply text-h4 md:text-d3 lg:text-d2 font-bold text-primary-500;
  }
  .typo-h2 {
    @apply text-h3 md:text-h2 lg:text-h1 font-normal md:font-bold text-white;
  }
  .typo-h3 {
    @apply text-h5 md:text-h4 lg:text-h3 font-normal md:font-medium text-white;
  }
  .typo-h4 {
    @apply text-h5 lg:text-h4 font-normal md:font-medium text-white;
  }
  /* .typo-h4 {
    @apply text-[16px] lg:text-[18px] font-normal md:font-medium text-white;
  } */
  .typo-b1 {
    @apply text-b2 md:text-b1 font-normal text-white-700;
  }
  /* shadows */
  .shadow-primary-02 {
    box-shadow: 0px 0px 6px 0px rgba(251, 162, 0, 0.35);
  }
  .btn-link {
    @apply !text-primary;
  }
  .background-1 {
    background-color: rgb(var(--background-1)) !important;
  }
  .background-3 {
    background-color: rgb(var(--background-3));
  }
  .bg-layout {
    @apply transition-all duration-1000 bg-cover bg-no-repeat;
  }
  .background-pattern-01 {
    @apply bg-layout;
    background: url("./patterns/01.webp") repeat-y;
  }

  .background-pattern-modal {
    background: url("./patterns/01.webp") repeat-y;
  }

  .background-pattern-02 {
    @apply bg-layout;
    background-image: url("./patterns/02.webp");
  }
  body {
    @apply background-pattern-01;
  }
  .bg-glass-7 {
    @apply bg-[#0007];
  }
  .toggle {
    background-color: #d4d4d4 !important;
    border: none;
    box-shadow: var(--handleoffsetcalculator) 0 0 3px #555 inset,
      0 0 0 3px #555 inset, var(--togglehandleborder);
  }
  .toggle:checked,
  .toggle[checked="true"],
  .toggle[aria-checked="true"] {
    box-shadow: var(--handleoffsetcalculator) 0 0 3px var(--primary) inset,
      0 0 0 3px var(--primary) inset, var(--togglehandleborder);
    background-color: #555 !important;
    --handleoffsetcalculator: var(--handleoffset);
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
  }
}

@layer utilities {
}

.phone-preview .flag-dropdown {
  @apply hidden;
}
.phone-preview input {
  background: #0000 !important;
  @apply !border-none !w-fit;
}
.phone-preview .react-tel-input .form-control {
  @apply !bg-[#0000] !text-white-500 !font-medium !text-b1 !w-fit !p-0 left-[116px] bottom-[4px] select-none !cursor-default;
}

.form-container {
  @apply bg-[#2b2b2b80] px-8 py-12 -mx-10;
}

.bg-transparent {
  @apply bg-[#2b2b2b80];
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari, and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

*::-webkit-scrollbar {
  @apply transition-all duration-300;
  width: 8px;
  transition: 0.3s;
}

*::-webkit-scrollbar-track {
  @apply transition-all duration-300;
  transition: 0.3s;
  border-radius: 8px;
  background-color: #0000;
  border: none;
}

*::-webkit-scrollbar-thumb {
  @apply transition-all duration-300;
  cursor: grab;
  transition: 0.3s;
  border-radius: 8px;
  border: 2px solid transparent;
  background-clip: content-box;
  background-color: #0000;
}

body::-webkit-scrollbar-thumb {
  @apply transition-all duration-300;
  background-color: #fff1;
  @apply hover:bg-[#fff3];
}

*:hover::-webkit-scrollbar-thumb {
  @apply hover:bg-[#fff3];
  border-radius: 8px;
  border: 2px solid transparent;
  background-clip: content-box;
  background-color: #fff1;
}

.modal:not(dialog:not(.modal-open)),
.modal::backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}
