.table {
  /* rounded-xl border border-black-400 */
  @apply overflow-hidden bg-none rounded-lg;
}
.table * {
  @apply text-b3 md:text-b2 lg:text-b1 text-center;
}

.table thead tr {
  /* bg-black-500 */
  @apply border-b border-black-400 first-letter:capitalize;
}
.table thead tr th:nth-child(1) {
  @apply rounded-ss-xl;
}
.table thead tr th:last-child {
  @apply rounded-se-xl;
}
.table thead tr th {
  @apply text-gray-300 py-6 font-medium;
}

/* .table :where(thead, tbody) :where(tr:not(:last-child)),
.table :where(thead, tbody) :where(tr:first-child:last-child) {
  background-color: #383838;
} */

.table :where(thead, tbody) {
  background-color: #0000;
}

.table tr.active,
.table tr.active:nth-child(odd),
.table-zebra tbody tr:nth-child(odd) {
  @apply bg-black-500/65;
}
.table-zebra tbody tr:nth-child(even) {
  background: rgba(var(--background-4), 65%);
}

.table-zebra tbody tr:last-child {
  @apply [&_th]:rounded-es-xl;
}
.table-zebra tbody tr:last-child td:last-child {
  @apply rounded-ee-xl;
}

.table tbody tr {
  @apply border-none;
}
.table tbody tr td {
  @apply font-normal;
}
.table thead tr th {
  /* @apply bg-[#464646] text-[#b5b5b5] text-center; */
  @apply text-white-700 text-center;
  background: rgba(var(--background-5), 96%);
}

.table.table_border tbody tr td {
  @apply border-l !border-white-800/30;
}

.table.table_border tbody tr {
  border-bottom: 1px solid;
  @apply !border-white-800/30;
}

.table.table_border tbody tr td:first-child {
  @apply !border-none;
}
.table.table_border tr:last-child {
  border-bottom: none;
  @apply !border-white-800/0;
}

.table.table_border tbody tr:last-child {
  border-bottom: 0px solid #0000 !important;
}

.Toastify__toast {
  @apply !p-0 relative;
}
.Toastify__toast-theme--dark {
  @apply !bg-transparent;
}

.Toastify__toast-body {
  @apply !p-0;
}
.Toastify__close-button {
  width: 24px !important;
  height: 24px !important;
  border: 1px solid !important;
  @apply !absolute right-4 top-4 !opacity-100 !border-primary !flex !justify-center !items-center rounded-full;
}
.Toastify__close-button svg {
  @apply ml-[2px];
}
.Toastify__close-button * {
  @apply text-primary;
}

.Toastify .Toastify__toast {
  @apply !card;
}

.svg-link.active * {
  @apply fill-primary-500;
}
