.Button {
  @apply transition-all duration-200 [&_*]:transition-all [&_*]:duration-200 gap-2 box-border overflow-hidden relative inline-flex cursor-pointer select-none flex-wrap items-center justify-center text-center px-4 first-letter:capitalize font-medium no-underline;
}

.Button.outline:disabled {
  @apply cursor-auto;
}

.Button.contain.primary {
  @apply bg-primary-500 text-black-500 [&_*]:text-black-500;
}
.Button.contain.primary:hover {
  @apply bg-primary-600;
}
.Button.contain.primary:active {
  @apply bg-primary-700;
}

.Button.contain.secondary {
  @apply bg-secondary-500 text-black-500 [&_*]:text-black-500;
}
.Button.contain.secondary:hover {
  @apply bg-secondary-600;
}
.Button.contain.secondary:active {
  @apply bg-secondary-700;
}
.Button.contain.black {
  @apply bg-black-900 text-white-500 [&_*]:text-white-500;
}
.Button.contain.black:hover {
  @apply bg-black-400;
}
.Button.contain.black:active {
  @apply bg-black-300;
}

.Button.contain.white {
  @apply bg-white-500 text-black-500 [&_*]:text-black-500;
}
.Button.contain.white:hover {
  @apply bg-white-600;
}
.Button.contain.white:active {
  @apply bg-white-700;
}

.Button.contain:disabled {
  @apply !bg-[#515151] !text-[#000] 
  /* @apply !bg-gray-200 !text-white-500 [&_*]:!text-white-500; */;
}

/*  */
.Button.outline {
  /* outline-style: 1px solid; */
  outline-style: initial;
  border: 1px solid;
  @apply font-normal;
}
.Button.outline.primary {
  @apply border-primary-500 text-primary-500 [&_*]:text-primary-500;
}
.Button.outline.primary:hover {
  @apply border-primary-600 text-primary-600 [&_*]:text-primary-600;
}
.Button.outline.primary:active {
  @apply border-primary-700 text-primary-700 [&_*]:text-primary-700;
}

.Button.outline.secondary {
  @apply border-secondary-500 text-secondary-500 [&_*]:text-secondary-500;
}
.Button.outline.secondary:hover {
  @apply border-secondary-600 text-secondary-600 [&_*]:text-secondary-600;
}
.Button.outline.secondary:active {
  @apply border-secondary-700 text-secondary-700 [&_*]:text-secondary-700;
}

.Button.outline.black {
  @apply border-black-900 text-black-900 [&_*]:text-black-900;
}
.Button.outline.black:hover {
  @apply border-black-400 text-black-400 [&_*]:text-black-400;
}
.Button.outline.black:active {
  @apply border-black-300 text-black-300 [&_*]:text-black-300;
}

.Button.outline.white {
  @apply border-white-500 text-white-500 [&_*]:text-white-500;
}
.Button.outline.white:hover {
  @apply border-white-600 text-white-600 [&_*]:text-white-600;
}
.Button.outline.white:active {
  @apply border-white-700 text-white-700 [&_*]:text-white-700;
}

.Button.outline:disabled {
  @apply !border-[#515151] !text-[#515151] [&_*]:!text-[#515151];
}

/*  */

.Button.text.primary {
  @apply text-primary-500 [&_*]:text-primary-500;
}
.Button.text.primary:hover {
  @apply text-primary-600 [&_*]:text-primary-600;
}
.Button.text.primary:active {
  @apply text-primary-700 [&_*]:text-primary-700;
}

.Button.text.secondary {
  @apply text-secondary-500 [&_*]:text-secondary-500;
}
.Button.text.secondary:hover {
  @apply text-secondary-600 [&_*]:text-secondary-600;
}
.Button.text.secondary:active {
  @apply text-secondary-700 [&_*]:text-secondary-700;
}

.Button.text.black {
  @apply text-black-900 [&_*]:text-black-900;
}
.Button.text.black:hover {
  @apply text-black-400 [&_*]:text-black-400;
}
.Button.text.black:active {
  @apply text-black-300 [&_*]:text-black-300;
}

.Button.text.white {
  @apply text-white-500  [&_*]:text-white-500;
}
.Button.text.white:hover {
  @apply text-white-600  [&_*]:text-white-600;
}
.Button.text.white:active {
  @apply text-white-700  [&_*]:text-white-700;
}

.Button.text:disabled {
  @apply text-gray-200 [&_*]:text-gray-200;
}

.Button.xs {
  @apply px-2 h-6 text-[10px] rounded-md;
}

.Button.sm {
  @apply px-4 h-8 text-b2 rounded-lg;
}

.Button.md {
  @apply px-4 h-10 text-b1 rounded-[10px];
}

.Button.lg {
  @apply px-4 h-12 text-[20px] rounded-xl;
}

.Button.xl {
  @apply px-4 h-14 text-h3 rounded-xl;
}
